<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>
        </b-row>

        <b-card
        >
          <b-form
                  @submit.prevent="searchByCondition"
                  @reset.prevent="resetCondition"
          >
            <b-row>
              <!--发货单编号-->
              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="发货单编号:"
                        label-for="inOrderNo"
                        label-size="sm"
                >
                  <b-form-input
                          v-model="state.condition.inOrderNo"
                          name="inOrderNo"
                          class="d-inline-block mr-1"
                          size="sm"
                          placeholder="发货单编号"
                  />
                </b-form-group>
              </b-col>

              <!--关联单号-->
              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="关联单号:"
                        label-for="relationNo"
                        label-size="sm"
                >
                  <b-form-input
                          v-model="state.condition.relationNo"
                          name="inOrderNo"
                          class="d-inline-block mr-1"
                          size="sm"
                          placeholder="关联单号(采购单/入库单)"
                  />
                </b-form-group>
              </b-col>

              <!--供应商-->
              <b-col
                      cols="12"
                      md="4"
              >
                <xy-input-button
                        label="供应商"
                        type="input"
                        v-on:change="fromChildren($event,['supplierName','supplierId'])"
                        :params="['supplierName','supplierId']"
                        :value="state.condition.supplierName"
                        modalName="供应商"
                        placeholder="点击选择供应商名称"
                >
                </xy-input-button>
              </b-col>


              <!--签约主体-->
              <b-col md="4" cols="12" class="mb-1"
              >
                <xy-input-button
                        label="签约主体"
                        type="input"
                        v-on:change="fromChildren($event,['companyName','companyId'])"
                        :params="['companyName','companyId']"
                        :value="state.condition.companyName"
                        modalName="销售主体"
                        placeholder="点击选择签约主体"
                >
                </xy-input-button>
              </b-col>

              <b-col
                      cols="12"
                      md="12"
                      class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="deliveryNotePrint(1)"
                >
                  <span class="align-right">打印发货单</span>
                </b-button>

                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="deliveryNotePrint(2)"
                >
                  <span class="align-right">批量打印发货单</span>
                </b-button>

                <b-button
                        variant="outline-primary"
                        @click="exportToExcel()"
                >
                  <span class="align-right">导出</span>
                </b-button>

                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-success"
                        @click="selectAllRows"
                        class="mr-1"
                        size="sm"
                >
                  <feather-icon
                          icon="CheckCircleIcon"
                          class="mr-50"
                  />
                  <span class="align-middle">全部勾选</span>
                </b-button>

                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"
                        @click="clearSelected"
                        class="mr-1"
                        size="sm"
                >
                  <feather-icon
                          icon="Trash2Icon"
                          class="mr-50"
                  />
                  <span class="align-middle">清空勾选</span>
                </b-button>



              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
        selected-variant="warning"
        select-mode="multi"
        selectable
        @row-selected="onRowSelected"
      >

        <template #row-details="data">
          <purchase-inbound-order-item-list :in_order_id="data.item.in_order_id"  />
        </template>


        <template #cell(in_order_no)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                    icon="MinusSquareIcon"
                    size="15"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==true"
            />
            <feather-icon
                    icon="PlusSquareIcon"
                    size="16"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          [#{{data.item.in_order_id}}]
          <br>{{data.item.in_order_no}}
        </template>


        <!-- Columns -->
        <template #cell(add_date)="data">
          {{ toDate(data.item.add_date) }}
        </template>

        <template #cell(send_time)="data">
          {{ toDate(data.item.send_time) }}
        </template>

        <template #cell(delivery_time)="data">
          {{ toDate(data.item.delivery_time) }}
        </template>

        <template #cell(inbound_time)="data">
          {{ toDate(data.item.inbound_time) }}
        </template>

        <template #cell(op_time)="data">
          {{ toTime(data.item.op_time) }}
        </template>

        <template #cell(order_id)="data">
          <a target="_blank" :href="toPoURL(data.item.order_id)">
            {{data.item.order_no}}
          </a>
        </template>

        <template #cell(inbound_id)="data">
          <a target="_blank" :href="toPoIURL(data.item.inbound_id)">
            {{data.item.inbound_no}}
          </a>
        </template>

        <template #cell(car_detail)="data">
          {{getCodeLabel('fee_transport_setting_type', data.item.car_detail)}}
        </template>

        <template #cell(transport_type)="data">
          {{getCodeLabel('transport_type', data.item.transport_type)}}
        </template>

        <template #cell(inbound_creator)="data">
          {{getCodeLabel('user', data.item.inbound_creator)}}
        </template>
          <!--备注-->
          <template #cell(memo_wh)="data">
              <div :id="`memo-${data.item.id}`">
                <span v-if="data.item.memo_wh">
                   {{ data.item.memo_wh.substring(0, 10) }}
                   <span v-if="data.item.memo_wh.length>10">...</span>
                </span>
              </div>
                 <b-tooltip :target="`memo-${data.item.id}`" placement="top">
                     {{ data.item.memo_wh }}
                 </b-tooltip>
          </template>

        <!--备注-->
        <template #cell(memo_wh)="data">
          <div :id="`memo-${data.item.id}`">
                <span v-if="data.item.memo_wh">
                   {{ data.item.memo_wh.substring(0, 10) }}
                   <span v-if="data.item.memo_wh.length>10">...</span>
                </span>
          </div>
          <b-tooltip :target="`memo-${data.item.id}`" placement="top">
            {{ data.item.memo_wh }}
          </b-tooltip>
        </template>





      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, reactive, onMounted, toRefs } from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty, lodopWatermark
} from '@core/utils/filter'
import purchaseinboundorderUseList from './purchaseinboundorderUseList'
import purchaseinboundorderStore from './purchaseinboundorderStore'
import Notification from 'element-ui/packages/notification'
import XyInputButton from '@/views/components/xy/XyInputButton'
import PurchaseInboundOrderItemList from '@/views/apps/purchaseinboundorderitem/PurchaseInboundOrderItemList'
import { getLodop,printStyle,reloadData } from '@/libs/LodopFuncs'
import lodopStore from '@/views/apps/lodop/lodopStore'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton,
    PurchaseInboundOrderItemList
  },
  methods: {
    searchByCondition() {
      this.refetchData()
    },
    resetCondition() {
      this.state.condition = {}
      store.commit('purchaseinboundorder/updateCondition', this.state.condition)
      this.refetchData()
    },
    selectAllRows() {
      this.$refs.refListTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.refListTable.clearSelected()
    },
  },
  data() {
    return {
      rowItem: []
    }
  },
  setup() {
    // Register module
    if (!store.hasModule('purchaseinboundorder')) store.registerModule('purchaseinboundorder', purchaseinboundorderStore)
    if (!store.hasModule('lodop')) store.registerModule('lodop', lodopStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseinboundorder')) store.unregisterModule('purchaseinboundorder')
      if (store.hasModule('lodop')) store.unregisterModule('lodop')
    })

    const state = reactive({
      condition: {},
    })

    onMounted(() => {
      state.condition = store.getters['purchaseinboundorder/getCondition']
    })

    const toPoURL = function (urlId) {
      return "/apps/purchaseorder/list?order_id=" + urlId
    }

    const toPoIURL = function (urlId) {
      return "/apps/purchaseinbound/list?inbound_id=" + urlId
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.state.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const exportToExcel = function () {
      const condition = state.condition
      const params = {
        inOrderNo: condition.inOrderNo,
        relationNo: condition.relationNo,
        supplierId:condition.supplierId,
        companyId:condition.companyId,
      }
      if(isEmpty(this.rowItem)){
          Notification({
            title: '失败',
            message: "请选择导出行",
            type: 'error'
          });
          return false;
      }else if(this.rowItem.length>1){
        Notification({
          title: '失败',
          message: "只能单行数据导出!",
          type: 'error'
        });
        return false;
      }else {
        params.inOrderNo =  this.rowItem[0].in_order_no
      }
      store.dispatch('purchaseinboundorder/exportToExcel', params)
              .then(res => {
                // if (res.data.code === 0) {
                  console.log(999,res)
                  let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
                  let filename = "发货单-"+new Date().getTime();
                  /*-------------*/
                  const link = document.createElement('a')
                  link.style.display = 'none'
                  link.href = URL.createObjectURL(data);
                  link.setAttribute('download',filename+'.xlsx')
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                // }
                // else {
                //   Notification({
                //     title: '失败',
                //     message: res.data.data,
                //     type: 'error'
                //   });
                // }
              })
    }

    const onRowSelected = function(item) {
      this.rowItem = item
    }

    const deliveryNotePrint = function (status) {
      if (this.rowItem.length == 0) {
          Notification({
            title: '失败',
            message: '请选择发货单!',
            type: 'error'
          });
        return false
        }else {
         var promise = Promise.resolve()
           for (let i = 0; i < this.rowItem.length; i++) {
             promise = promise.then(() => {
               return    deliveryNotePrintSingle(this.rowItem[i].in_order_id,status)
             })
           }

      }
    }
    const deliveryNotePrintSingle = function (id,status) {
      return new Promise((resolve, reject) => {
        store.dispatch('lodop/createPurchaseInboundOrderDeliveryLODOP', {
          ids: id
        }).then(res => {
                  if (res.data.code === 0) {
                    const data = res.data.data
                    var LODOP = getLodop()
                    if (LODOP == undefined) {
                      Notification({
                        title: '失败',
                        message: '打印插件未安装，请安装插件!',
                        type: 'error'
                      });
                      return
                    }
                    LODOP.PRINT_INIT('打印发货单') //打印初始化
                    LODOP.SET_PRINT_STYLE('FontSize', 18) //设置对象风格
                    LODOP.SET_PREVIEW_WINDOW(2, 2, 0, 0, 0, '')//设置窗口
                    for (let i = 0; i < data.length; i++) {
                      //根据不同的供应商选择不同的打印模板
                      reloadData(data[i],LODOP)
                    }
                    if(status===1){
                      LODOP.PREVIEW() // 设置预览（如果使用预览，每次打印前会弹出预览窗口，根据需要决定是否使用）
                    }
                    LODOP.PRINT()
                    resolve()
                  } else {
                    Notification({
                      title: '失败',
                      message: res.data.data,
                      type: 'error'
                    });
                  }
                })
      })
    }


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = purchaseinboundorderUseList()

    return {
      ...toRefs(state),
      state,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime,
     toDate,
      fromChildren,toPoURL,toPoIURL,exportToExcel,onRowSelected,deliveryNotePrint,printStyle,reloadData,deliveryNotePrintSingle
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
