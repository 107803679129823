import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function purchaseinboundorderUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'in_order_no', label: '发货单编号', sortable: true },
    { key: 'order_id', label: '采购单编号', sortable: true },
    { key: 'inbound_id', label: '入库单编号', sortable: true },
    { key: 'add_date', label: '订单日期', sortable: true },
    { key: 'supplier_name', label: '供应商名称', sortable: true },
    { key: 'total_qty', label: '发货总量', sortable: true },
    { key: 'total_amount', label: '发货总金额', sortable: true },
    { key: 'company_name', label: '客户名称', sortable: true },
    { key: 'location', label: '收货地址', sortable: true },
    { key: 'contact', label: '联系人', sortable: true },
    { key: 'mobile', label: '联系电话', sortable: true },
    { key: 'send_time', label: '发货日期', sortable: true },
    { key: 'delivery_time', label: '到货日期', sortable: true },
    { key: 'inbound_time', label: '签收日期', sortable: true },
    { key: 'transport_type', label: '运输方式', sortable: true },
    { key: 'company_name_transport', label: '运输单位', sortable: true },
    { key: 'car_detail', label: '车型', sortable: true },
    { key: 'car_no', label: '车号', sortable: true },
    { key: 'memo_wh', label: '备注', sortable: true },
    { key: 'inbound_creator', label: '制单人', sortable: true },
    { key: 'op_time', label: '操作时间', sortable: true },
    //  { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('in_order_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['purchaseinboundorder/getCondition']}
    store
      .dispatch('purchaseinboundorder/search', {
        search_key: searchKey.value,
        inOrderNo: condition.inOrderNo,
        relationNo: condition.relationNo,
        supplierId:condition.supplierId,
        companyId:condition.companyId,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
